import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import Jumbotron from "../Jumbotron"
import IntroWhat from "./IntroWhat"
import Footer from "../Footer"
import LazyShow from "../helpers/LazyShow"
import Pvasuserjourney from "./Pvasuserjouney"
import PvasDescriptions from "./PvasDescriptions";

const What = () => {
  const [height, setHeight] = useState(window.innerHeight);

    /*get us to the top of the page always */
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slides = [
    {
      id: 1,
      title: "Sovellukset mahdollistavat suorituskykyjä",
      content: "Pikaviesti-, puhe- ja tilannekuvasovelluksilla saavutetaan kykyjä, jotka aiemmin eivät olleet kuviteltavissa ilman valtavia kustannuksia. Lokakuussa 2023 saatavilla on tilannekuvasovellus TAK (Tactical Awareness Kit) avaimet käteen -sovelluspalveluna.",
      bgImage: "src/assets/images/jumbotron/tähtäin.jpeg",
    },
    {
      id: 2,
      title: "Tilauspalvelu mahdollistaa laajamittaisen käytön",
      content: "Tilauspalvelu on PVPILVI Public Cloudissa toimiva verkkokauppa, josta on saatavilla siihen integroidut sotilassovellukset. Selaa katalogia, valitse tarvitsemasi palvelu, tilaa ja vastaanota kyky liittää taistelijasi tilaamasi palveluun - woltmaisen helposti, ilman edeltäviä erityistaitoja.",
      bgImage: "src/assets/images/jumbotron/rakennettu.jpeg",
    },
    {
      id: 3,
      title: "PVATK mahdollistaa sovelluksia, Tilauspalvelun ja tulevaisuuden",
      content: "Olemme luoneet vapaaehtoiseen maanpuolustukseen perustuvan sovelluskehittäjäyksikkö PVATK:n. PVATK on ydin, joka mahdollistaa sovelluskehityksen joukkoistamisen askel kerrallaan: tähtäimessä Puolustusvoimien oma sovelluskehitys ja osallistava maanpuolustus.",
      bgImage: "src/assets/images/jumbotron/ryhmä.jpeg",
    },
  ];

  return (
    
    <div>
            <Jumbotron 
                id="home" 
                backgroundImage="src/assets/images/jumbotron/sanla.jpeg" 
                title="PVARKI" 
                subtitle="Johtamisen tuen uusi sukupolvi"
            />

    <div style={{ height: `${height}px` }}>
    <LazyShow>
      <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        effect={"fade"}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 30000,
          disableOnInteraction: true
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide
            key={slide.id}
            style={{
              backgroundImage: `url(${slide.bgImage})`,
              height: `${height}px`,
            }}
          >
            <div className="content">
              <h1>{slide.title}</h1>
              <p>{slide.content}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      </>
      </LazyShow>
      <LazyShow>
        <>
          <IntroWhat />
        </>
      </LazyShow>
      <LazyShow>  
        <>
        <PvasDescriptions />
        </>
      </LazyShow>
      <LazyShow>  
        <>
        <Pvasuserjourney />
        </>
      </LazyShow>
      <Footer />

    </div>
    </div>
  );
};

export default What;