import React from 'react';

interface ThingProps {
  title: string;
  text: React.ReactNode;
  image: string;
  right?: boolean;
}

const Thing: React.FC<ThingProps> = ({ title, text, image, right }) => (
  <div className={`thing ${right ? 'right' : ''}`}>
    <div className="thing-image">
      <img src={image} alt={title} />
    </div>
    <div className="thing-content">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  </div>
);

const IntroRecruit: React.FC = () => (
  <div className="container">
    <div className="introcomponent">
      <h1>Rekrytointi</h1>
      <Thing 
        title="Oletko taistelija?" 
        text={ 
          <><strong>Rakennamme</strong> parhaillaan kykyjä osallistua kehittämiseen. Olemme käynnistämässä henkilökunta- ja reserviläistaustaisille sotilasosaajille <strong>koekäyttöohjelmaa</strong>, jossa pääset suoraan yhteyteen kehittäjien kanssa osaamisalueesi mukaan. Osallistuminen vaatii tiettyä sitoutumista, mutta ei enempää kuin voit aikaasi käyttää - voit pitää käytettävyytesi tilan ajan tasalla. Tulemme myös luomaan kanavat, joilla kertoa tarpeistaan turvaluokka julkisessa muodossa ilman erityistä sitoutumista - "toiveiden tynnyrin". 
          </> }
        image="src/assets/images/icons/trooper3.png"
      />
      <Thing 
        title="Oletko kehittäjä?" 
        text={ 
          <>Digiprojektin ohjaama vapaaehtoinen <strong>sovelluskehittäjäyksikkö PVATK</strong> rekrytoi. Yksikkö luo parhaillaan Tilauspalvelua ja mallituotteen TAK integrointia tilattavaksi. Etsimme kehittäjärooleihin muun muassa sovelluskehittäjiä, tuoteomistajia, scrum mastereita ja devops-osaajia. Kokemukseltasi voit olla juniori tai seniori - tärkeintä on tahto osallistua, ja vähintään noin 2-4 tunnin verran aikaa osallistua kuukaudessa. </>}
        image="src/assets/images/icons/swdev.png" 
        right 
      />
      <Thing 
        title="Oletko mediaosaaja?" 
        text={ 
          <>Rekrytoimme myös mediaosaajia vapaaehtoiseen maanpuolustukseen <strong>sovelluskehittäjäyksikkö PVATKöön</strong>. Esimerkiksi tuotteiden käyttöohjeistus ja siviilivälineiden hallintaan taistelukentällä kohdistuva koulutus onnistuisi parhaiten video- ja elokuvamateriaalilla. Jos omaat tuotantokokemusta ohjaajana, käsikirjoittajana, kuvaajana ja leikkaajana, tervetuloa mukaan! Toisaalta, open source -joukkoistaminen, mitä tavoittelemme, edellyttää mm. sisällöntuotannon ja yhteisöalustamoderoinnin osaamista. Tule rakentamaan osallistavaa maanpuolustusta!</> }
        image="src/assets/images/icons/filmer.png" 
      />
    </div>
  </div>
);

export default IntroRecruit;

