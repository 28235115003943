import React from 'react';

interface JumbotronProps {
  id: string;
  backgroundImage: string;
  title: string;
  subtitle: string;
}

const Jumbotron: React.FC<JumbotronProps> = ({ id, backgroundImage, title, subtitle }) => {
  return (
    <div className="jumbotron" id={id} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="inner">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
    </div>
  );
};

export default Jumbotron;
