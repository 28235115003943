import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import Jumbotron from "../Jumbotron"
import Intro from "../Why/Intro"
import Footer from "../Footer"
import LazyShow from "../helpers/LazyShow"
import ProductDescriptions from "./ProductDescriptions";

const Home = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slides = [
    {
      id: 1,
      title: "Tilannekuva suoraan puhelimeesi",
      content: "Miten saada viesti liikkumaan helposti? Siviililaitteita voi käyttää sotilastehtäviin olosuhteiden salliessa. Tämä sivusto esittelee, kuinka se toimii. Tarjoamme pian palvelun, josta saat tueksesi muun muassa komppanian tehtäviin suunnitellut palvelut suoraan puhelimeesi. ",
      bgImage: "src/assets/images/jumbotron/home.jpg",
    },
    {
      id: 2,
      title: "Esittelyssä digiprojektin PVARKI Järjestelmäksi lopputuotteet",
      content: "Sivusto esittelee sekä siviilivälineiden sotilaskäyttöä helpottavat alustat, että nämä mahdollistavat uudet toiminnot Puolustusvoimissa. Digiprojekti PVARKI Järjestelmäksi loi vapaaehtoisvoimin näistä prototyypit, joita rakennetaan nyt tuotantoon.",
      bgImage: "src/assets/images/jumbotron/rakennettu3.jpeg",
    },
    {
      id: 3,
      title: "Uusia kykyjä, uusin keinoin",
      content: "Vapaaehtoinen maanpuolustus on voimamme.  Pidemmällä aikavälillä luomamme uudet toiminnot Puolustusvoimille kehittävät sekä osallistavaa maanpuolustusta, että Puolustusvoimien omaa kykyä johtaa sovelluskehitystä tarpeisiinsa. Etsimme vapaaehtoisia mukaan koekäyttäjiksi, kehittäjiksi ja mediaosaajiksi.",
      bgImage: "src/assets/images/jumbotron/why.jpg",
    },
  ];

  return (
    
    <div>
            <Jumbotron 
                id="home" 
                backgroundImage="src/assets/images/jumbotron/taistelija.jpeg" 
                title="PVARKI" 
                subtitle="Johtamisen tuen uusi sukupolvi"
            />

    <div style={{ height: `${height}px` }}>
    <LazyShow>
      <>
      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        effect={"fade"}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 30000,
          disableOnInteraction: true
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide
            key={slide.id}
            style={{
              backgroundImage: `url(${slide.bgImage})`,
              height: `${height}px`,
            }}
          >
            <div className="content">
              <h1>{slide.title}</h1>
              <p>{slide.content}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      </>
      </LazyShow>
      <LazyShow>
        <>
          <Intro />
        </>
      </LazyShow>
      <LazyShow>  
        <>
        <ProductDescriptions />
        </>
      </LazyShow>
      <Footer />

    </div>
    </div>
  );
};

export default Home;
