import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label: string
}

export const Button = ({ label, onClick, className, disabled, type = "button" }: Props) => {
    const buttonClasses = classNames(
        "button",
        className,
        { "disabled": disabled }  // Here I assume you want to add a "disabled" class when the button is disabled
    );

    return (
        <button
            className={buttonClasses}
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {label}
        </button>
    );
};

const Authbutton = ({ label, className, disabled, type = "button" }: Props) => {
    const { loginWithRedirect } = useAuth0();

    const buttonClasses = classNames(
        "button",
        className,
        { "disabled": disabled } 
    );

    return (
        <button
            className={buttonClasses}
            onClick={() => loginWithRedirect()}
            disabled={disabled}
            type={type}
        >
            {label}
        </button>
    );
};

export default Authbutton;
