import React from 'react';
import Description from '../Description';

const ProductDescriptions: React.FC = () => {
  const icons = [
    { image: 'src/assets/images/icons/jager.png', title: 'Lähellä taistelijaa', description: 'Tilauspalvelun korkein arvo on tuottaa joukoille hyötyä. Tämä tapahtuu asettamalla taistelija ensimmäiseksi. Johtamisen tuen järjestelmien ei tule monimutkaisuudella viedä huomiota itseensä, vaan niiden tulee mahdollistaa huomion ja osaamisen keskittäminen ydinbisnekseen - tehtävään. Ei enää konfigurointia ja käppyröitä!' },
    { image: 'src/assets/images/icons/tarkkailla.png', title: 'Voiman moninkertaistaja', description: 'Kun ratkaisut ovat helppoja käyttää, ne mahdollistavat. Syvenny uusien apuvälineiden tuomiin mahdollisuuksiin, kun aikasi ei kulu siihen, että saat ne ylipäänsä käsiisi, ja toiseksi toimintaan. Sen lisäksi, että Tilauspalvelusta saatavien tuotteiden käyttöönotto- ja käyttökokemus muotoillaan ystävälliseksi, kaikkien tuotteiden mukana toimitetaan nykyaikainen mobiiliohje.' },
    { image: 'src/assets/images/icons/kehitys.png', title: 'Kehitys kehittyy', description: 'Ensimmäinen julkaisu on ensimmäinen. Sekä tilauspalvelu, että sen tarjonta kehittyy jatkuvasti. Avainroolissa tarjonnan kehittymiselle olet sinä - tuo tarpeesi PVARKI-kehitysvastuulle, me analysoimme sen. Alkuvaiheessa kehitysvastuu perustuu Puolustusvoimien johtamaan vapaaehtoiseen ydinjoukkoon, PVATK. Rakentuessaan PVATK tulee tarjoamaan ulkopuolisille tuotetiimeille tiedot, reunaehdot ja työkalut tuotteiden kehittämiseksi Tilauspalveluun.' },
  ];

  return (
    <div className="my-component">
      <Description
        title="Alustana Tilauspalvelu"
        text="Kaikilla palveluilla on tarjoajansa. Tähän asti sotilaille on ollut mahdollista 3. osapuolen tarjoamien palveluiden, kuten Signalin, hyödyntäminen. Tilauspalvelu on game changer. Se on alusta, jolle sotilaiden tarpeisiin luodut palvelut ja ratkaisuohjeet tulevat tilattavaksi, Puolustusvoimien tarjoamana. \n\nTilauspalvelu tarjoaa sovelluspalveluita (SaaS): sinun ei tarvitse huolehtia palvelimesta, vaan ainoastaan siitä, miten käytät tilaustasi. Eikä siinä kaikki - Tilauspalvelusta saatavien tuotteiden käyttö- ja käyttöönottokokemus on muotoiltu ja muotoillaan jalkaväkimiestä tarkoin kuunnellen niin, että niiden käyttöönotto ja peruskäyttö ei vaadi erityistä koulutusta."
        icons={icons}
      />
    </div>
  );
};

export default ProductDescriptions;
