import React from 'react';

interface ThingProps {
  title: string;
  text: React.ReactNode;
  image: string;
  right?: boolean;
}

const Thing: React.FC<ThingProps> = ({ title, text, image, right }) => (
  <div className={`thing ${right ? 'right' : ''}`}>
    <div className="thing-image">
      <img src={image} alt={title} />
    </div>
    <div className="thing-content">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  </div>
);


const IntroHow: React.FC = () => (
  <div className="container">
    <div className="introcomponent">
      <h1>Miten?</h1>
      <Thing 
        title="Digiprojektilla pyörät liikkeelle" 
        text={
          <>Digiprojektissa Puolustusvoimien ohjaamat vapaaehtoiset ovat luoneet PVATK-sovelluskehittäjäyksikön, sekä tuottaneet prototyyppeinä Tilauspalvelun ja muut tällä sivulla esitellyt uudet toiminnot. <strong>Rakennamme edelleen toimintaa.</strong> Seuraava askel on siirtyä projektista osaksi jatkuvia toimintoja Puolustusvoimissa. Se mahdollistaa skaalauksen ylöspäin - sivulla rakennettaviksi mainitut toiminnot syntyvät pala palalta!</>}
        image="src/assets/images/icons/next.png" 
      />
      <Thing 
        title="Kokeillen eteenpäin" 
        text={
          <>Seuraavat askeleet niin Tilauspalvelun käytöstä, kuin vapaaehtoisen sovelluskehityksen laajentamisesta sekä Puolustusvoimien johtamasta sovelluskehityksestä tehdään <strong>kokeillen</strong>. Kokeillen luomme muun muassa: Tilauspalvelun palvelumallin, eli ketkä tarvitsevat tilausoikeuden, Puolustusvoimien että mahdollisesti ulkopuolisten tukijoiden tuen vapaaehtoiselle sovelluskehitykselle, sekä yhteyden joukoista kehittäjiin - koekäyttäjäohjelman - niin, että tällä sivulla esiteltyihin tavoitteisiin päästään. Askel kerrallaan.</>}
        image="src/assets/images/icons/testing.png" 
        right 
      />
      <Thing 
        title="Tavoite korkealla" 
        text={<>Pitkällä aikavälillä tavoitteemme on uusi johtamisen tuen sukupolvi, missä helppokäyttöisyys yhdistyy moderneihin suorituskykyihin ja jakelumenetelmiin. Toisaalta tavoittelemme luoda sekä teknologiaosaamista <strong>osallistavan maanpuolustuksen</strong> muodon, että Puolustusvoimille kyvyn sen <strong>itse johtamaan sovelluskehitykseen.</strong></>} 
        image="src/assets/images/icons/korkealle.png" 
      />
    </div>
  </div>
);

export default IntroHow;

