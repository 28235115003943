import './styles/index.scss';
import './index.css'

import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <Auth0Provider             
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            onRedirectCallback={(appState) => {
                window.history.replaceState(
                    {},
                    document.title,
                    appState?.returnTo || window.location.pathname
                );
            }}
          >
          <App />
        </Auth0Provider>
      </BrowserRouter>
  </React.StrictMode>
);
