import React from 'react';
import Description from '../Description';

const ProductDescriptions: React.FC = () => {
  const icons = [
    { image: 'src/assets/images/icons/pikaviesti.png', title: 'Pikaviestit', description: 'Samin sotilaspoliisikomppania suojaa voimalaa. Ajoneuvopartiolla Sami lähettää pikaviestin ohittaessaan peitepisteen, minkä komentopaikka kuittaa - tiedämme, että silmämme ovat juuri nyt täällä.' },
    { image: 'src/assets/images/icons/puhe.png', title: 'Ääni', description: 'Palatessapäin Sami havaitsee kaksi autoa pysähtyneenä noin kilometrin voimalasta sijaitsevan muuntamon läheisyydessä. Hän huomaa autojen rekisterinumerojen olevan tavatessa ilmoitettavien listalla. Sami kutsuu komentopaikkaa sovelluksen radiopuhelinmaisella push-to-talk -toiminnolla.' },
    { image: 'src/assets/images/icons/tiku2.png', title: 'Tilannekuva', description: 'Mikko seuraa komentopaikalla täplien liikettä sovelluksen kartalla. Saadessaan Samin ilmoituksen hän luo huomio-merkin autojen sijaintiin ja käskee kaksi partiota välittömälle kiinniottotehtävälle, minne nämä osaavat suoraan tukikohdastaan mennä huomio-merkin perusteella.' },
    { image: 'src/assets/images/icons/jotainuutta2.png', title: 'Aivan uusia kykyjä', description: 'Lähiön asukkaat ovat huomanneet useiden entuudestaan vieraiden miesten menevän ja tulevan asuntoon, jossa ei ennen vaikuttanut asuvan kukaan. Sofia ja Kaisa ottavat kuvan heidän käyttämistään autoista tiedonkeruusovellukseen, varustaen ne tageilla "epäilyttävä" ja "agentti." Koneellinen käsittelyjärjestelmä vie kuvat voimalaa suojaavan paikallispataljoonan käsiteltäväksi, missä käsittelijä huomaa autojen olevan ilmoitettavien listalla. Hän pyyhkäisee oikealle, saa kuvien metatiedot ja välittää potentiaalisen turvatalon osoitteen sotilaspoliisikomppanialle.' },
  ];

  return (
    <div className="my-component">
      <Description
        title="Esimerkkejä palveluista joukoille"
        text="Komppaniat, eli noin alle kahdensadan taistelijan yksiköt alaisineen, ovat erityinen kohderyhmä. Komppanioille siviilivälineiden sotilaskäyttö tuo sellaisen johtamisen tuen, joka sopii juuri omiin tarpeisiin ja jonka voi viedä niin alas, kuin tarve on. PVARKI-käytön voi joustavasti suunnitella ja toimeenpanna komppanian sisällä, mikäli ylemmän johtoportaan uhkakuva sallii tämän. \n\nDigiprojektin lopputuotteet tarjoavat työkalut, jolla tämä on joukolle mahdollista ilman erityistä koulutusta tai edeltävää osaamista."
        icons={icons}
      />
    </div>
  );
};

export default ProductDescriptions;
