import React, { useEffect, useState } from "react";
import "swiper/swiper-bundle.css";
import Jumbotron from "../Jumbotron"
import IntroRecruit from "./IntroRecruit"
import Footer from "../Footer"
import LazyShow from "../helpers/LazyShow"
import RecruitDescription from "./RecruitDescriptions";

const Recruitment = () => {
  const [height, setHeight] = useState(window.innerHeight);

    /*get us to the top of the page always */
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    
    <div>
            <Jumbotron 
                id="home" 
                backgroundImage="src/assets/images/jumbotron/kosseli.jpeg" 
                title="PVARKI" 
                subtitle="Johtamisen tuen uusi sukupolvi"
            />

    <div style={{ height: `${height}px` }}>
      <LazyShow>
        <>
          <IntroRecruit />
        </>
      </LazyShow>
      <LazyShow>
        <>
        <RecruitDescription/>
        </>
      </LazyShow>
      <Footer />

    </div>
    </div>
  );
};

export default Recruitment;