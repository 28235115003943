import React from 'react';

interface ThingProps {
  title: string;
  text: React.ReactNode;
  image: string;
  right?: boolean;
}

const Thing: React.FC<ThingProps> = ({ title, text, image, right }) => (
  <div className={`thing ${right ? 'right' : ''}`}>
    <div className="thing-image">
      <img src={image} alt={title} />
    </div>
    <div className="thing-content">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  </div>
);

const IntroWhat: React.FC = () => (
  <div className="container">
    <div className="introcomponent">
      <h1>Mitä?</h1>
      <Thing 
        title="TAK - mallituote" 
        text={<>Yhdysvaltain puolustusministeriön julkaisema Tactical Awareness Kit koostuu palvelimesta ja asiakasohjelmista, jotka on saatavilla Androidille, iOSille ja Windowsille. Vuodesta 2010 kehitetty <strong>tilannekuvasovellus</strong> on muun muassa Yhdysvaltain ja Britannian eri puolustushaarojen ja niiden erikoisjoukkojen operatiivisessa käytössä. Sovellus mahdollistaa monipuoliset tilannekuvajärjestelmän toiminnot ilman lisenssikustannuksia. Mallituotteena tilattavissa tilauspalvelusta, kun se julkistetaan lokakuussa 2023.</>}
        image="src/assets/images/icons/taklogo.png" 
      />
      <Thing 
        title="Tilauspalvelu - jakelu, helposti" 
        text={<>Tilauspalvelu on alustasi saada sotilaskäyttöön palveluita, joita käytät siviililaitteilla. Tilauspalvelusta voit selata tarjontaa ja tilata tarvitsemasi palvelun. Automaatio toimittaa sinulle kyvyn liittää ja hallita taistelijoitasi juuri sinulle luotuun palvelimeen. Liittyvää taistelijaa vastassa on hänen avaimensa tilattuun palveluun ja käyttöohje. Palvelulupaus: pois lukien suunnitteluusi kuluva aika, tilaamasi tuote on perustasoisessa <strong>käytössä puolessa tunnissa.</strong></>}
        image="src/assets/images/icons/tilauspalvelu.png" 
        right 
      />
      <Thing 
        title="PVATK - vapaaehtoisuudesta voimaa" 
        text={<>Ilman kykyä luoda uutta ei synny uutta. Vapaaehtoinen sovelluskehittäjäyksikkö <strong>PVATK</strong> on rakentuva ydinjoukko, jonka tavoite on toimittaa joukoille sitä, mitä ne tarvitsevat. PVATK luo Puolustusvoimille kokemusta sen itsensä johtamasta sovelluskehityksestä - ketteryyttä, kustannustehokkuutta ja virallisestikin alhaalta ylös etenevää ideointia. Samalla saamme erityisosaavien reserviläisten ja siviilien kyvyt käyttöön yhteisen turvallisuuden hyväksi.</>}
        image="src/assets/images/icons/pvatk.png" 
      />
    </div>
  </div>
);

export default IntroWhat;

