import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Logoutbutton from "./authentication/Logoutbutton"

const Footer = () => {

    const { logout } = useAuth0();
  
    const handleLogout = () => {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    };

    return (
    <div className="footer">
        <hr />
        <div className="inner">
        <p>Digiprojekti PVARKI Järjestelmäksi - PV ak AS18370</p>
        </div>
        <hr className="small" />
        <div className="inner copy">
        <p>Design & tech Benjam Bröijer &copy; 2023</p>
        <p>Kuvitus &copy; Puolustusvoimat</p>
        <br />
        <p>Yhteys:</p>
        <p>benjam.broijer@mil.fi</p>
        <p>Maasotakoulu, Puolustusvoimat</p>
        </div>

        <hr className="small" />
        <div className="inner copy">
        <br />
        <Logoutbutton label="Kirjaudu ulos" onClick={handleLogout} type="submit" />
      </div>
    </div>
    );

};
export default Footer;