import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    label: string
}

const Logoutbutton = ({ label, className, disabled, type = "button" }: Props) => {
  
    const { logout } = useAuth0();

    const buttonClasses = classNames(
        "button",
        className,
        { "disabled": disabled } 
    );

    return (
        <button
            className={buttonClasses}
            onClick={() => logout()}
            disabled={disabled}
            type={type}
        >
            {label}
        </button>
    );
};

export default Logoutbutton;
