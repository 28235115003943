import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/autoplay";
import Jumbotron from "../Jumbotron"
import IntroHow from "./IntroHow"
import Footer from "../Footer"
import LazyShow from "../helpers/LazyShow"
import Processroadmap from "./Processroadmap"

const How = () => {
  const [height, setHeight] = useState(window.innerHeight);

    /*get us to the top of the page always */
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slides = [
    {
      id: 1,
      title: "Uusi tuotekehitys...",
      content: "Ilman kykyä luoda uutta, ei synny uutta. Asiakkaan tarpeiden - joukkojen tarpeiden - tulee olla luomisen keskiössä. Ensimmäinen Miten?-tavoitteemme on mahdollistaa se, että joukkojen tarpeita kyetään nopeasti kuulemaan ja täyttämään. Tämä vaatii sekä yhteyden joukkoihin, että kehitysvastuun, joka kykenee sitä ketterästi hyödyntämään. PVARKI on tähän mainio koekenttä.",
      bgImage: "src/assets/images/jumbotron/kosseli.jpeg",
    },
    {
      id: 2,
      title: "...Puolustusvoimien johtamana...",
      content: "Tarpeiden nopea kuuleminen edellyttää ketteryyttä, ja ketteryys edellyttää alhaalta ylöspäin nousevaa toimintaa. Toinen Miten?-tavoitteemme on vakiinnuttaa prosessi, missä ideoista luodaan PV:n johtamana konsepteja, konsepteista prototyyppejä, ja prototyypeistä tuotantoonvietäviä tuotteita. Päätöksentekijät päättävät prototyyppien jatkoresursoinnista, ei konseptien kilpailutuksesta.",
      bgImage: "src/assets/images/jumbotron/kasky.jpeg",
    },
    {
      id: 3,
      title: "...ensimmäisenä resurssina maanpuolustustahto.",
      content: "Esitelty sisältö on luotu vapaaehtoisten ammattilaisten voimin. Kolmas Miten?-tavoitteemme on mahdollistaa sekä teknologiaosaamista osallistava maanpuolustus - ensin vapaaehtoisin, sitten reservin joukolla, lopulta varusmiehillä - että teknologiasektorin tuki vapaaehtoisuudelle ja PV:n johtamalle sovelluskehitykselle. Samalla selvitettävä on kaupallisten hyödyntäminen.",
      bgImage: "src/assets/images/jumbotron/ryhmä2.jpeg",
    },
  ];

  return (
    <div>
            <Jumbotron 
                id="home" 
                backgroundImage="src/assets/images/jumbotron/kiikarit.jpeg" 
                title="PVARKI" 
                subtitle="Johtamisen tuen uusi sukupolvi"
            />

    <div style={{ height: `${height}px` }}>
    <LazyShow>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        effect={"fade"}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 30000,
          disableOnInteraction: true
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide
            key={slide.id}
            style={{
              backgroundImage: `url(${slide.bgImage})`,
              height: `${height}px`,
            }}
          >
            <div className="content">
              <h1>{slide.title}</h1>
              <p>{slide.content}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      </LazyShow>
      <LazyShow>
        <>
          <IntroHow />
        </>
      </LazyShow>  
      <LazyShow>
        <>
          <Processroadmap />
        </>
      </LazyShow>  
      <Footer />

    </div>
    </div>
  );
};

export default How;
