import React from "react";
import Icons from "../helpers/iconcomponents/Icons"

export default function Pvasuserjourney() {
  const Roadinfo = [
    {
      info: "Hanki pääsy Tilauspalveluun",
      name: "Kirjaudu sisään",
      desc: "Kirjautuminen tapahtuu luvitetulla sähköpostilla. Sen, ketkä tarvitsevat ja saavat oikeuden, selvitämme testaamalla.",
      icon: <Icons icon={`/src/assets/images/icons/avain.png`} />,
    },
    {
      info: "Selaa verkkokauppamaista katalogia",
      name: "Tutki tarjontaa",
      desc: "Saatavilla olevat palvelut kertovat kuin verkkokaupan tuotteet itsestään: mihin ne sopivat, ja mitä kalustoa niiden käyttö edellyttää.",
      icon: <Icons icon={"src/assets/images/icons/kazo.png"} />,
    },
    {
      info: "Tilaa, mitä tarvitset",
      name: "Tilaa palvelu",
      desc: "Tilaa tilauskolikoillasi. Tilauksesta automaatio pystyttää tilaamasi palvelun juuri sinulle Jaettavien kolikoiden määrä selvitetään kokeilemalla.",
      icon: <Icons icon={"src/assets/images/icons/tilaa.png"} />,
    },
    {
      info: "Vastaanota, suunnittele ja toimeenpane",
      name: "Liitä taistelijasi",
      desc: "Saat tilauksesta avaimet, millä pääset Johtajan ja Ylläpitäjän käyttöliittymään. Liitä taistelijasi QR-koodilla ja hallitse heidän pääsyään palveluusi napeilla.",
      icon: <Icons icon={"src/assets/images/icons/alikessu.png"} />,
    },
    {
      info: "Taistelijan toimenpiteet",
      name: "Ota palvelu käyttöön",
      desc: "Jakamastasi QR-koodista taistelija pääsee selaimella henkilökohtaiselle perustesivulleen. Sivu sisältää pikaohjeen ja keinot liittyä tilattuun palveluun.",
      icon: <Icons icon={"src/assets/images/icons/byod2.png"} />,
    },
    {
      info: "30 minuuttia tilauksesta",
      name: "Kovaa ajoa!",
      desc: "Palvelulupauksemme on, että tilauksesta noin puolessa tunnissa palvelu on taistelijoillasi perustasoisessa käytössä - poislukien käytön suunnitteluun ja käskemiseen käyttämäsi aika.",
      icon: <Icons icon={"src/assets/images/icons/sota.png"} />,
    },
  ];
  return (
    <div className="roadmap flex column gap">  <div className="roadmap-box text-center">
        <div className="roadmap-box__subtitle sub">
          <h3></h3>
        </div>
        <div className="roadmap-box__title">
        <h2 className="white-text">Tilauspalvelu: Näin se toimii</h2>
        </div>
        <div className="roadmap-box__subtitle sub">
          <br />
          <h3>Palvelulupauksemme:</h3>
          <br />
          <h3>1. Palvelusi käytössä kolmessakymmenessä minuutissa</h3>
          <h3>2. Tilaaminen ei vaadi erityistä koulutusta tai osaamista</h3>
          <h3>3. Peruskäyttö, esim TAK:n, ei vaadi erityistä koulutusta</h3>
        </div>
      </div>
      <div className="roadmap-inner flex gap">
        {Roadinfo.map(({ info, name, desc, icon }, index) => {
          return (
            <div className="roadmap-inner__map flex column gap-1 a-start">
              <div className="roadmap-inner__icon flex j-center a-center">
                {icon}
              </div>
              <h3 className="roadmap-inner__data">{info}</h3>
              <h3 className="roadmap-inner__name">{name}</h3>
              <p className="roadmap-inner__desc sub">{desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
