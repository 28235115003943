import React from 'react';

interface ThingProps {
  title: string;
  text: React.ReactNode;
  image: string;
  right?: boolean;
}

const Thing: React.FC<ThingProps> = ({ title, text, image, right }) => (
  <div className={`thing ${right ? 'right' : ''}`}>
    <div className="thing-image">
      <img src={image} alt={title} />
    </div>
    <div className="thing-content">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  </div>
);

const Intro: React.FC = () => (
  <div className="container">
    <div className="introcomponent">
      <h1>Miksi?</h1>
      <Thing 
        title="Uusia suorituskykyjä joukollesi" 
        text={<>Lähetä, vastaanota ja hallitse joukkosi havaintoja välittömästi. Johda joukkoasi puheella ja tekstillä liikkeestäkin. Rakenna sensoriverkko ja seuraa sen kuvaa, tai hyödynnä sinulle tarjottua dataa suoraan maastossa. Tee tämä mobiililaitteellasi - sitä on PVARKI. Julkaistava Tilauspalvelu mahdollistaa esimerkiksi paikallisjoukon komppanialle tämän ilman erityisiä kustannuksia tai osaamisvaatimuksia. </>}
        image="src/assets/images/icons/trooper3.png" 
      />
      <Thing 
        title="Tuo oma laitteesi" 
        text={<>PVARKI tarkoittaa omien (Bring Your Own Device - BYOD) tai saatavilla olevien (Commercial Off-The-Shelf - COTS) laitteiden sotilaskäyttöä. Paitsi kustannustehokasta, myös mahdollistavaa. Yhdistä: jokaisella taistelijallasi on laite. Saat sovelluksen kaikille käyttöön puolessa tunnissa. Laitteet mahdollistavat helpot ja huipputeknologiset sovellukset. Tulos: kykyjä, joita hieman aiemmin ei ole voinut kuvitellakaan.</>}
        image="src/assets/images/icons/byod2.png" 
        right 
      />
      <Thing 
        title="Turvallisuutta taistelukentältä" 
        text={<>Siviililaitteiden turvallisuus on korkealla tasolla, sillä Internetiin yhteydessä olevat päätelaitteet ovat osa kybertaistelukenttää joka päivä, jo nyt. Sotilaskäyttö toimintaympäristönä tuo siviililaitteille suhteessa rajallisen ja hallittavissa olevan määrän riskejä. Esimerkiksi elektroninen sodankäynti on uhkana varsin paikallinen.</>}
        image="src/assets/images/icons/cyber2.png" 
      />
    </div>
  </div>
);

export default Intro;

