import React, { useEffect, useState } from 'react';

interface IconProps {
  icon: string;
}

const Icons: React.FC<IconProps> = ({ icon }) => {
  const [iconSize, setIconSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const iconContainer = document.querySelector('.roadmap-inner__icon');
    if (iconContainer) {
      const containerWidth = iconContainer.clientWidth;
      const containerHeight = iconContainer.clientHeight;
      setIconSize({ width: containerWidth, height: containerHeight });
    }
  }, []);

  const imagePath = `${icon}`;

  return <img src={imagePath} className="icon" style={iconSize} />;
};

export default Icons;
