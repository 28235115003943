import React from 'react';

interface Icon {
  image: string;
  title: string;
  description: string;
}

interface DescriptionProps {
  title: string;
  text: string;
  icons: Icon[];
}

const Description: React.FC<DescriptionProps> = ({ title, text, icons }) => {
  const paragraphs = text.split('\n').map((item, key) => {
      return <p key={key} className="description-text">{item.split('\\n').map((text, index) => <React.Fragment key={index}>{text}<br /></React.Fragment>)}</p>;
  });

  return (
    <div className="description">
      <h1 className="description-title">{title}</h1>
      {paragraphs}
      <div className="icon-container">
        {icons.map((icon, index) => (
          <div key={index} className="icon">
            <img className="icon-image" src={icon.image} alt={icon.title} />
            <h2>{icon.title}</h2>
            <p className="icon-description">{icon.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Description;

