import React from 'react';

interface Icon {
  image: string;
  title: string;
  description: string;
}

interface DescriptioncontactProps {
  title: string;
  text: string;
  contact1: string;
  contact2: string;
  contact3: string;
  contact4: string;
  icons: Icon[];
  logo: string;
}

const Descriptioncontact: React.FC<DescriptioncontactProps> = ({ title, text, icons, contact1, contact2, contact3, contact4, logo}) => {
  const paragraphs = text.split('\n').map((item, key) => {
      return <p key={key} className="description-text">{item.split('\\n').map((text, index) => <React.Fragment key={index}>{text}<br /></React.Fragment>)}</p>;
  });

  return (
    <div className="description">
      <h1 className="description-title">{title}</h1>
      {paragraphs}
      <div className="icon-container">
        {icons.map((icon, index) => (
          <div key={index} className="icon">
            <h2>{icon.title}</h2>
            <p className="icon-description">{icon.description}</p>
          </div>
        ))}
      </div>
      <h1 className="description-title">{contact1}</h1>
      <div className="description-title">{contact2}</div>
      <div className="description-title">{contact3}</div>
      <div className="description-title">{contact4}</div>
      <div className="description-title">
      <div className="logocontainer">
      <img src={logo} style={{width: "200px"}} />

      </div>
      </div>
    </div>
  );
};

export default Descriptioncontact;
