import { AppRouter } from "./AppRouter";
import { useMatch, NavLink, useLocation } from "react-router-dom";


const App = () => {
  const location = useLocation();

  return (
    <>
      <div className="navbar">
        <div className="navbar-logo">
          <NavLink to="/">
            <img src="src/assets/images/pvatk.jpg" alt="Logo"></img>
          </NavLink>
          <span className="navbar-span"> | </span>
        </div>

        <span className="navbar-title">PVARKI</span>
        {location.pathname !== '/login' && (
          <div className="navbar-links">
            <CustomNavLink to="/" label="Miksi?" />
            <CustomNavLink to="/what" label="Mitä?" />
            <CustomNavLink to="/how" label="Miten?" />
            <CustomNavLink to="/recruitment" label="Rekry" />
          </div>
        )}
      </div>

        <AppRouter />

    </>
  );
};


interface CustomNavLinkProps {
  to: string;
  label: string;
}


const CustomNavLink: React.FC<CustomNavLinkProps> = ({ to, label }) => {
  let match = useMatch(to);
  return (
    <NavLink to={to} className={match ? "active-link" : ""}>
      {label}
    </NavLink>
  );
};

export default App;

