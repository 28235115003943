import React from "react";
import Icons from "../helpers/iconcomponents/Icons"

export default function Processroadmap() {
  const Roadinfo = [
    {
      info: "Asiakasyhteytemme ja oma K&K:",
      name: "Tunnista tarpeet",
      desc: "Yhteys joukkoihin: toiveiden tynnyri, mutta ennen kaikkea koekäyttäjäohjelma, missä halukkaat ammattisotilaat ja reserviläiset tuodaan lähelle kehittäjiä.",
      icon: <Icons icon={`/src/assets/images/icons/jotainuutta2.png`} />,
    },
    {
      info: "Päättäjät ja oma K&K:",
      name: "Priorisoi tarpeet",
      desc: "Päättäjätaso priorisoi tarpeita analyytikoiden esityksestä. Toisaalta osa tarpeista saisi olla pienellä kuratoinilla saatavilla esimerkiksi paikallisille kehittäjätiimeille, jotta innostukselle annetaan mahdollisuuksia.",
      icon: <Icons icon={"src/assets/images/icons/kazo.png"} />,
    },
    {
      info: "Oma K&K, paikalliset tiimit tai kumppanit:",
      name: "Luo konsepti",
      desc: "Konsepti sisältää sotilastarinoita, mitä halutaan saada aikaan. Tässä vaiheessa emme välttämättä tiedä, miten toteutus tarkalleen ottaen menee - siihen päästään askel kerrallaan.",
      icon: <Icons icon={"src/assets/images/icons/maali.png"} />,
    },
    {
      info: "Oma K&K, paikalliset tiimit tai kumppanit:",
      name: "Suunnittele POC tai MVP",
      desc: "Sotilastarinoista luodaan toteutuksen ja tarpeen osoittamiseen tähtäävä Proof of Concept tai Minimum Viable Product. Ensin tämä suunnitellaan ja katsotaan, kuka tekee, mitä tekee, ja kuka resursoi.",
      icon: <Icons icon={"src/assets/images/icons/suunnittele.png"} />,
    },
    {
      info: "K&K-ydin, paikalliset tiimit tai kumppanit:",
      name: "Tee POC tai MVP",
      desc: "Teknologisen toteutuksen luominen on edullista olla askel kerrallaan, jatkuvasti sekä ajatuksia että toteutusta testaavaa toimintaa. Tätä on POC - luodaan nopeasti, jotta nähdään arvo nopeasti.",
      icon: <Icons icon={"src/assets/images/icons/rakenna.png"} />,
    },
    {
      info: "Päättäjät ja tukijat:",
      name: "Hyväksy tai hylkää",
      desc: "Jatkoresursoinnista päätökset POC/MVP:n perusteella: panostetaan ensin vähän, jotta nähdään arvo nopeasti ja vähällä, viedään meriittien mukaan tuotantoon, mikä on kalliimpaa.",
      icon: <Icons icon={"src/assets/images/icons/paatos.png"} />,
    },
    {
      info: "K&K-ydin, paikalliset tiimit tai kumppanit:",
      name: "Vie (laajempaan) tuotantoon",
      desc: "Hyväksyttäessä POC/MVP, viedään se tuotantoon oman mallimme mukaisesti. Tähän työhön käytetään resursseja - joko vapaaehtoisia PVATK:sta, paikallisista tiimeistä tai kumppaneilta.",
      icon: <Icons icon={"src/assets/images/icons/tuotantoon.png"} />,
    },
    {
      info: "Päättäjät:",
      name: "Julkaise!",
      desc: "Ensimmäisen tuotantoversion odotusten täyttyessä julkaistaan tuote Tilauspalvelussa oman julkaisumallimme mukaisesti, mikä tulee luoda. Tavoite: CI/CD, jatkuva kehitys resurssien mukaan, ei jäykkyyttä.",
      icon: <Icons icon={"src/assets/images/icons/ajossaon.png"} />,
    },
    
  ];
  return (
    <div className="roadmap flex column gap">
      <br />
      <br />
      <div className="roadmap-box text-center">
        <div className="roadmap-box__subtitle sub">
          <h3></h3>
        </div>
        <div className="roadmap-box__title">
          <h2 className="white-text">Sovelluskehityksen prosessi</h2>
        </div>
        <div className="roadmap-box__subtitle sub">
          <br />
          <h3>Motto: 'Kehitystä alhaalta päin, jatkuvasti testaten'</h3>
          <br />
          <h3>Prosessi rakentuu kokeillen. Parhaillaan työskentelemme tuotantoonviennin askeleen kohdalla, koska itse Tilauspalvelu on tässä tilanteessa. Seuraavaksi kokeiltavina ovat muun muassa PV:n suoraan johtamien sovelluskehittäjätiimien sijaan paikallisten tiimien hyödynnys konseptien ja prototyyppien luonnissa, ja mahdollinen ulkopuolinen tuki vapaaehtoisuudelle. Yksittäisten sovellusten kohdalla prosessin tulee olla nopea - Tilauspalvelua nopeampi - jotta jatkuvaan testaamiseen esimerkiksi rajoitetun, paikallisen tuotannon avulla päästään. Ei vuosikausien megaprojekteja!</h3>
        </div>
      </div>
      <div className="roadmap-inner flex gap">
        {Roadinfo.map(({ info, name, desc, icon }, index) => {
          return (
            <div className="roadmap-inner__map flex column gap-1 a-start">
              <div className="roadmap-inner__icon flex j-center a-center">
                {icon}
              </div>
              <h3 className="roadmap-inner__data">{info}</h3>
              <h3 className="roadmap-inner__name">{name}</h3>
              <p className="roadmap-inner__desc sub">{desc}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
  
}
