import React from 'react';
import Descriptioncontact from './Descriptioncontact';

const RecruitDescriptions: React.FC = () => {
  const icons = [
    { image: '', title: 'Kehittäjä ja mediaosaaja - PVATK', description: 'Saat kutsujaltasi linkin Discord-keskustelualustallemme. Alustalla vastassa odottaa rekrytointilomake. Täytettyäsi rekrylomakkeen, saat perusmuotoisen näkyvyyden discord-tilaan. Voit osallistua keskusteluun, ja lähestymme sinua ilmoittamiesi vapaaehtoisuuksien perusteella sitä mukaa toisaalta, kun tarve nousee, ja toisaalta kykymme onboardingiin kasvaessa.' },
    { image: '', title: 'Ammattisotilaat - Koekäyttöohjelma', description: 'Mikäli olet henkilökuntaa, toistainen yhteys on digiprojektin PVARKI Järjestelmäksi johtaja, opettaja Benjam Bröijer Maasotakoululta. Rakennamme pian henkilökunnalle Tuve-alustan, johon ilmoittaa vapaaehtoisuutensa sekä osaamis-/kiinnostusalansa. Tuve-alustalla sijaitsee ohjeistus siitä, kuinka voit osallistua koekäyttöohjelmaan. Tervetuloa tuomaan sotilaan näkemystä tarpeista kehittäjille!' },
    { image: '', title: 'Sotilasosaaja - Koekäyttöohjelma', description: 'Mikäli olet reserviläinen sotilasosaaja, saat kehittäjien onboardingin tapaan kutsujaltasi linkin Discordiin - vastassa odottaa rekrylomake. Rekrylomakkeessa tiedustellaan osaamistasi sotilasosaaja-näkökulmasta. Täytettyäsi rekrylomakkeen, saat perusmuotoisen näkyvyyden Discord-tilaamme, ja voit osallistua keskusteluihin. Toistaiseksi otamme sinuun yhteyden - rakennamme sinulle kykyä omatoimisempaan osallistumiseen!' },
  ];

  return (
    <div className="my-component">
      <Descriptioncontact
        title="Kiinnostuitko?"
        text="Kehittäjänä ja mediaosaajana olet tervetullut aluksi keskustelualustallemme, ja onboarding-kykymme ja tarpeemme mukaan vapaaehtoiseen sovelluskehittäjäyksikkö PVATK:öön. Sotilasosaajille käynnistämme pian koekäyttäjäohjelman. Toistaiseksi rekrytointimenetelmämme on luottamusverkosto. Sovelluskehittäjissä, mediaosaajissa tai koekäyttäjäohjelmassa mukana oleva voi kutsua sinut mukaan hyödyntäen tätä sivua landing pagena. \n\nMikäli olet kiinnostunut vapaaehtoisen sovelluskehityksen tai tuoteprojektien tukemisesta muutoin, ota yhteys Benjamiin."
        icons={icons}
        contact1="Yhteys"
        contact2="Benjam Bröijer"
        contact3="benjam.broijer@mil.fi"
        contact4="Maasotakoulu, Puolustusvoimat"
        logo='src/assets/images/icons/pvatk.png'
      />
    </div>
  );
};

export default RecruitDescriptions;
