import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0Button from "./Auth0Button";

export const UserLoginView = () => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  };

  return (
    <div className="jumbotron" id="login" style={{ backgroundImage: `url(${'src/assets/images/jumbotron/tähtäin.jpeg'})` }}>
      <div className="inner">
        <div className="inner2">
          <h1>PVARKI</h1>
          <div className="inner3">
            <Auth0Button label="Kirjaudu sisään!" onClick={handleSubmit} type="submit" />
            </div>
        </div>
      </div>
    </div>
  );
};
